'use client';

import { PlayIcon } from '@heroicons/react/solid';
import Image from 'next/image';
import { useState } from 'react';

// eslint-disable-next-line import/no-named-as-default

const styles = {
  container: {
    background:
      'linear-gradient(180deg, rgba(242, 250, 255, 0) 0%, #F2FAFF 55.21%, rgba(242, 250, 255, 0) 100%)',
  },
  button: {
    boxShadow: '0px 12px 24px rgba(8, 35, 63, 0.2)',
  },
  btnContainer: {
    background: 'radial-gradient(#FFFFFF 0%, rgba(255, 255, 255, 0) 100%)',
  },
};

export const Video = () => {
  const [showVideo, setShowVideo] = useState(false);
  // const videoImage = [
  //   {
  //     imagePath: '/images/video/video-2x.png',
  //     imageWidth: 1024,
  //     alt: 'Video',
  //   },
  // ];

  return (
    <section style={styles.container}>
      <div className="container mx-auto flex items-center justify-center overflow-hidden px-4 md:mt-4 md:px-0">
        <div
          className="cursor-pointer overflow-hidden py-20 md:w-[44rem] lg:w-[60rem]"
          onClick={() => setShowVideo(true)}
        >
          {!showVideo ? (
            <div className="relative flex items-center justify-center">
              <div className="relative aspect-video w-full">
                {/* <OptimisedImage
                  data={videoImage}
                  defaultImagePath="/images/video/video-1x.png"
                /> */}
                <Image
                  src={
                    '/images/video/video-2x.png' || '/images/video/video-1x.png'
                  }
                  alt="Video"
                  width={1024}
                  height={576}
                />
                <div
                  style={styles.btnContainer}
                  className="absolute left-0 top-0 h-full w-full"
                />
              </div>
              <div className="absolute cursor-pointer">
                <button
                  style={styles.button}
                  className="rounded-md bg-[#318AE5] px-6 py-2 text-base text-white hover:bg-[#157be0] lg:rounded-2xl lg:p-6 lg:text-4xl"
                >
                  <PlayIcon className="inline h-8 w-5 lg:h-9 lg:w-9" /> Watch
                  Demo
                </button>
              </div>
              <div className="absolute -bottom-5 left-[50%] -z-10 h-10 w-[80%] translate-x-[-50%] bg-[rgba(0,0,0,0.1)] blur-lg" />
            </div>
          ) : (
            <iframe
              className={'aspect-video w-full'}
              src="https://www.youtube.com/embed/tPTmvPc17rw?autoplay=1&enablejsapi=1"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          )}
        </div>
      </div>
    </section>
  );
};
